import { useQuery } from "@tanstack/react-query";

import { fetchRestApi } from "../functions/utils";
// import { fetchMockTrackingInventory } from "../mock/mockFetch";
import { useMeQuery } from "./useMeQuery";

export const useTrackingInventoryQuery = () => {
  const { data: me } = useMeQuery();
  const section_id = me?.section_id;

  return useQuery<Array<Partial<TrackingInventory>>>({
    queryKey: ["tracking_inventory", section_id],
    queryFn: async () => fetchRestApi(`/tracking-inventory/${section_id}`),
    enabled: !!section_id,
    refetchInterval: 1000 * 10, // 10秒
    refetchIntervalInBackground: true,
  });
};
