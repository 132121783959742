import { Auth } from "aws-amplify";

const endpoint = process.env.REACT_APP_REST_API_ENDPOINT;

const getIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const fetchRestApi = async (path: string, init?: RequestInit) => {
  if (!endpoint) {
    console.error(
      "Environment Variable `REACT_APP_REST_API_ENDPOINT` is not set"
    );
    throw new Error(
      "Environment Variable `REACT_APP_REST_API_ENDPOINT` is not set"
    );
  }

  const response = await fetch(endpoint + path, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: await getIdToken(),
    },
  });

  if (!response.ok) {
    console.error("Network response was not ok.");
    throw new Error("Network response was not ok.");
  }

  return response.json();
};

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const zeroPadding = (number: number, length: number) => {
  return String(number).padStart(length, "0");
};

export const isActiveStatus = (status: Status | null | undefined) => {
  return (
    status === "DISPATCH" ||
    status === "SITE_ARRIVAL" ||
    status === "SITE_DISPATCH" ||
    status === "HOSPITAL_ARRIVAL"
  );
};

export const stringfyStatus = (status: Status | null | undefined) => {
  switch (status) {
    case "DISPATCH":
      return "出動";
    case "SITE_ARRIVAL":
      return "現着";
    case "SITE_DISPATCH":
      return "搬開";
    case "HOSPITAL_ARRIVAL":
      return "病着";
    case "HOSPITAL_DISPATCH":
      return "引揚";
    case "NOT_APPLICABLE":
      return "不明";
    default:
      return "--";
  }
};

export const stringfyBiologicalSex = (
  biological_sex: BiologicalSex | null | undefined
) => {
  switch (biological_sex) {
    case "MALE":
      return "男";
    case "FEMALE":
      return "女";
    case "UNKNOWN":
      return "不明";
    default:
      return "--";
  }
};
