import { useCallback, useState } from "react";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";

import { useTrackingInventoryQuery } from "./hooks/useTrackingInventoryQuery";
import { Spinner } from "./compenents/Spinner";
import { Button } from "./compenents/Button";
import { StatusIcon } from "./compenents/StatusIcon";
import {
  isActiveStatus,
  stringfyStatus,
  stringfyBiologicalSex,
} from "./functions/utils";
import { useTeamIdAtom } from "./hooks/useTeamIdAtom";
import { useMoveCamera } from "./hooks/useMapAtom";

export const ControlPanel = () => {
  const { data, isLoading, isError } = useTrackingInventoryQuery();
  const [teamId, setTeamId] = useTeamIdAtom();
  return (
    <div className="absolute top-3 left-3 px-3 w-112 max-h-[calc(100vh-1.5rem)] bg-white text-slate-900 shadow">
      {isError && (
        <p className="text-red-600 w-full h-full flex items-center justify-center gap-3 p-8">
          救急隊データの読み込みに失敗しました
        </p>
      )}
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center gap-3 p-8">
          <Spinner />
          <p className="text-slate-700">救急隊データを読み込み中です</p>
        </div>
      )}
      {data && (
        <>
          <TeamList
            trackingInventory={data}
            selectedTeamId={teamId}
            setSelectedTeamId={(id) => setTeamId(id)}
          />
          <TeamDetail
            team={data?.find((item) => item.device_id === teamId)}
            onClose={() => setTeamId(undefined)}
          />
        </>
      )}
    </div>
  );
};

const TeamDetail = ({
  team,
  onClose,
}: {
  team: Partial<TrackingInventory> | undefined;
  onClose: () => void;
}) => {
  if (team === undefined) {
    return null;
  }

  return (
    <section className="py-3 border-t border-slate-300">
      <div className="flex gap-3 items-center pb-1.5">
        <h2 className="font-bold">{team?.team_name}</h2>
        <div className="flex gap-0.5 items-center">
          <StatusIcon
            type={
              team?.is_difficult_case
                ? "danger"
                : isActiveStatus(team?.status)
                ? "active"
                : "inactive"
            }
          />
          <div className={`${team?.is_difficult_case ? "text-red-600" : ""}`}>
            {stringfyStatus(team?.status)}
            {team?.is_difficult_case && " （搬送困難）"}
          </div>
        </div>
        <div className="grow">{/* Spacer */}</div>
        <Button
          label="閉じる"
          icon={<CloseIcon fontSize="inherit" />}
          color="error"
          onClick={onClose}
        />
      </div>

      <ul className="overflow-y-scroll max-h-[calc(100vh-28rem)]">
        <li className={`${team?.is_difficult_case ? "text-red-600" : ""}`}>
          受入要請回数: {team?.inquiry_count ?? "--"}
        </li>
        <li>概要: {team?.jian_summary || "--"}</li>
        <li>年齢: {team?.age ?? "--"}</li>
        <li>性別: {stringfyBiologicalSex(team?.biological_sex)}</li>
        <li>主訴: {team?.chief_complaint || "--"}</li>
        <li>
          バイタル:
          <ul className="ml-4">
            <li>JCS: {team?.vital_sign?.jcs || "--"}</li>
            <li>
              GCS:{" "}
              {`E ${team?.vital_sign?.gcs_e ?? "-"}, V ${
                team?.vital_sign?.gcs_v ?? "-"
              }, M ${team?.vital_sign?.gcs_m ?? "-"}`}
            </li>
            <li>呼吸数: {team?.vital_sign?.respiration_rate ?? "--"}</li>
            <li>脈拍数: {team?.vital_sign?.pulse_rate ?? "--"}</li>
            <li>
              収縮期血圧: {team?.vital_sign?.systolic_blood_pressure ?? "--"}
            </li>
            <li>
              拡張期血圧: {team?.vital_sign?.diastolic_blood_pressure ?? "--"}
            </li>
            <li>SpO2: {team?.vital_sign?.spo2 ?? "--"}</li>
            <li>
              酸素投与後SpO2:{" "}
              {team?.vital_sign?.spo2_after_oxygen_administration ?? "--"}
            </li>
            <li>酸素投与経路: {team?.vital_sign?.oxygen_route || "--"}</li>
            <li>酸素流量: {team?.vital_sign?.oxygen_flow ?? "--"}</li>
            <li>
              体温: {team?.vital_sign?.body_temperature?.toFixed(1) ?? "--"}
            </li>
          </ul>
        </li>
      </ul>
    </section>
  );
};

const TeamList = ({
  trackingInventory,
  selectedTeamId,
  setSelectedTeamId,
}: {
  trackingInventory: Partial<TrackingInventory>[];
  selectedTeamId: string | undefined;
  setSelectedTeamId: (teamId: string | undefined) => void;
}) => {
  const [minInquiryCount, setMinInquiryCount] = useState<number | null>(null);
  const [statusGroup, setStatusGroup] = useState<"danger" | "active" | null>(
    "active"
  );
  const moveCamera = useMoveCamera();
  const handleClickTeamRow = useCallback(
    (team: Partial<TrackingInventory>) => {
      const lat = team.latitude;
      const lng = team.longitude;
      if (!lat || !lng) return;
      const zoom = 15;
      moveCamera({ lat, lng }, zoom);
      setSelectedTeamId(team.device_id);
    },
    [moveCamera, setSelectedTeamId]
  );

  return (
    <section className="py-3">
      <h2 className="font-bold mb-1.5">救急隊リスト</h2>
      <div className="mb-3 space-y-1">
        <InquiryCountFilter
          count={minInquiryCount}
          setCount={setMinInquiryCount}
        />
        <StatusGroupFilter
          statusGroup={statusGroup}
          setStatusGroup={setStatusGroup}
        />
      </div>
      <div className="max-h-40 overflow-y-scroll">
        <table className="table-fixed border-separate border-spacing-0 space-y-1.5 w-full">
          <thead className="text-xs">
            <tr className="sticky inset-0 drop-shadow bg-white">
              <th className="w-24">隊名</th>
              <th className="w-12">状態</th>
              <th className="w-8">要請</th>
              <th className="w-8">年齢</th>
              <th className="w-8">性別</th>
              <th className="w-auto">主訴</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {trackingInventory
              .filter(({ inquiry_count }) => {
                if (minInquiryCount === null) return true;
                if (inquiry_count === undefined) return false;
                return inquiry_count >= minInquiryCount;
              })
              .filter(({ is_difficult_case, status }) => {
                if (statusGroup === null) return true;
                if (statusGroup === "danger") return is_difficult_case;
                return isActiveStatus(status);
              })
              .map((item, i) => (
                <tr
                  key={item.device_id ?? i}
                  className={`cursor-pointer ${
                    item.device_id === selectedTeamId ? "bg-slate-100" : ""
                  } ${
                    item.is_difficult_case ? "text-red-500 font-bold" : ""
                  } hover:bg-slate-100`}
                  onClick={() => handleClickTeamRow(item)}
                >
                  <td className="truncate">{item.team_name ?? "----"}</td>
                  <td className="flex gap-0.5 items-center justify-center">
                    <StatusIcon
                      type={
                        item.is_difficult_case
                          ? "danger"
                          : isActiveStatus(item.status)
                          ? "active"
                          : "inactive"
                      }
                    />
                    <div>{stringfyStatus(item.status)}</div>
                  </td>
                  <td className="text-center">{item.inquiry_count ?? "--"}</td>
                  <td className="text-center">{item.age ?? "--"}</td>
                  <td className="text-center">
                    {stringfyBiologicalSex(item.biological_sex)}
                  </td>
                  <td className="truncate">{item.chief_complaint ?? "----"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const InquiryCountFilter = ({
  count,
  setCount,
}: {
  count: number | null;
  setCount: (count: number | null) => void;
}) => {
  return (
    <section className="bg-slate-100 rounded text-sm p-2">
      <div className="flex items-center justify-between mb-1.5">
        <h3 className="font-bold">受入要請回数で絞り込み</h3>
        <Button
          label="絞り込み解除"
          icon={<FilterAltOffIcon fontSize="inherit" />}
          color="error"
          onClick={() => setCount(null)}
          disabled={count === null}
        />
      </div>
      <div className="flex gap-1 items-center">
        {[1, 2, 3, 4].map((i) => (
          <Button
            key={i}
            label={`${i}回`}
            color={count === i ? "primary" : "secondary"}
            onClick={() => setCount(i)}
            disabled={count === i}
          />
        ))}
        <span className="text-sm">以上で絞り込む</span>
      </div>
    </section>
  );
};

const StatusGroupFilter = ({
  statusGroup,
  setStatusGroup,
}: {
  statusGroup: "danger" | "active" | null;
  setStatusGroup: (statusGroup: "danger" | "active" | null) => void;
}) => {
  return (
    <section className="bg-slate-100 rounded text-sm p-2">
      <div className="flex items-center justify-between mb-1.5">
        <h3 className="font-bold">事案の状態で絞り込み</h3>
        <Button
          label="絞り込み解除"
          icon={<FilterAltOffIcon fontSize="inherit" />}
          color="error"
          onClick={() => setStatusGroup(null)}
          disabled={statusGroup === null}
        />
      </div>
      <div className="flex gap-1 items-center">
        <Button
          label="搬送困難"
          icon={<StatusIcon type="danger" />}
          color={statusGroup === "danger" ? "primary" : "secondary"}
          onClick={() => setStatusGroup("danger")}
          disabled={statusGroup === "danger"}
        />
        <Button
          label="稼働中"
          icon={<StatusIcon type="active" />}
          color={statusGroup === "active" ? "primary" : "secondary"}
          onClick={() => setStatusGroup("active")}
          disabled={statusGroup === "active"}
        />
        <span className="text-sm">で絞り込む</span>
      </div>
    </section>
  );
};
