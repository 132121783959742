import { useQuery } from "@tanstack/react-query";

import { fetchRestApi } from "../functions/utils";
// import { fetchMockMe } from "../mock/mockFetch";

export const useMeQuery = () => {
  return useQuery<Partial<Me>>({
    queryKey: ["me"],
    queryFn: async () => fetchRestApi("/me"),
  });
};
