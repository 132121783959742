import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Popover from "@radix-ui/react-popover";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";

import { Button } from "./compenents/Button";

export const AccountPopover = ({
  me,
  signOut,
}: {
  me: Partial<Me>;
  signOut: () => void;
}) => {
  return (
    <div className="absolute top-3 right-3">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="rounded-full flex items-center justify-center bg-white drop-shadow text-blue-500">
            <AccountCircleIcon fontSize="large" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className=" bg-white p-3 w-56 rounded drop-shadow-lg"
            sideOffset={2}
            align="end"
          >
            <Popover.Arrow className="fill-white" />
            <div className="font-bold mb-2">{me.display_name ?? "--"}</div>
            <div className="flex justify-between items-center">
              <Button
                label="ログアウト"
                color="primary"
                icon={<LogoutIcon fontSize="inherit" />}
                onClick={() => signOut()}
              />
              <Popover.Close asChild>
                <Button
                  label="閉じる"
                  color="error"
                  icon={<CloseIcon fontSize="inherit" />}
                />
              </Popover.Close>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};
